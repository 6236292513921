import { combineReducers } from "@reduxjs/toolkit";

const userReducer = (state = null, action) => {
	switch (action.type) {
		case "LOGIN":
			return action.payload;
		case "LOGOUT":
			return null;
		default:
			return state;
	}
};

export default combineReducers({
	user: userReducer,
});
