import * as firebase from "firebase";

const firebaseConfig = {
	apiKey: "AIzaSyBfpKTGyuhRHjHoAq8VdH8UsLA0YE0QcRI",
	authDomain: "global-insurance-llc.firebaseapp.com",
	databaseURL: "https://global-insurance-llc.firebaseio.com",
	projectId: "global-insurance-llc",
	storageBucket: "global-insurance-llc.appspot.com",
	messagingSenderId: "798948350004",
	appId: "1:798948350004:web:095b7c16b8ac7217be9261",
	measurementId: "G-H2CJRXH8R4",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
const storage = firebase.storage();

export { firestore, storage };
