import { persistStore, persistReducer } from "redux-persist";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import reducers from "./reducers";

const reducer = persistReducer(
	{
		key: "root",
		storage: storage,
	},
	reducers
);
const store: Object = configureStore({
	reducer,
	middleware: getDefaultMiddleware({
		serializableCheck: false,
	}),
});

const persist = persistStore(store);
export default () => {
	return { store, persist };
};
